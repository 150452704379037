<template>
  <div v-if="$route.name != 'usetutorial'" class="nav_box">
    <div class="right-nav">
      <ul>
        <li
          v-for="item in navList"
          :key="item.id"
          @click="toggleRoute(item.id, item.route)"
          :class="{ activeLi: item.id == activeId }"
        >
            <span class="title_box">
                <span :style="item.id == activeId?'background-color:#1f6eff;':'background-color:#fff;'" class="line"></span>
                <span style="flex:1;">{{ item.name }}</span>
            </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
    ...mapState({
      navList: (state) => state.nav.navList,
    }),
    activeId:{
        get() {
            return this.$store.state.nav.activeId;
        },
        set(val) {
            this.$store.commit("nav/save", {activeId:val});
            },
        },
  },
  mounted() {
    console.log(this.$route,'---------sssssss')
    this.$nextTick(function () {
      this.activeId = window.sessionStorage.getItem("activeId");
    });
  },
  methods: {
    toggleRoute(id, route) {
      if(id == 4){
        this.activeId = id;
        this.$router.push(route+'/printRecordSearch');
      }else{
        this.activeId = id;
        this.$router.push(route);
      }
    },
  },
}
</script>

<style lang="less" scoped>
.nav_box {
    width: 135px;
    height: 100%;
    .right-nav {
        ul {
          li {
            height: 45px;
            line-height: 45px;
            text-align: center;
            font-size: 14px;
            color: #343639;
            cursor: pointer;
            .title_box {
                display: flex;
                height: 100%;
                width: 100%;
                .line {
                    display: inline-block;
                    width: 4px;
                    height: 100%;
                    background-color: #1f6eff;
                }
            }
           
          }
        }
       
    }
    .right-nav .activeLi {
        color: #1f6eff;
        background-color: #eff2f9;
    }
}
</style>